.sem_resultados {
    font-size: 1.5em;
    text-align: center;
}

.querys_avaliacao {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    margin-top: 10px;
    margin-bottom: 70px;
}

.filter_area {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: var(--dcp-gray-color);
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
        rgba(17, 17, 26, 0.1) 0px 16px 56px,
        rgba(17, 17, 26, 0.1) 0px 24px 80px;

    overflow-y: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
}

.filter_area_opened {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
}

.filter_area_closed {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
    100% {
        transform: translateX(0%);
    }
}

@-webkit-keyframes slide-in {
    100% {
        -webkit-transform: translateX(0%);
    }
}

@keyframes slide-out {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@-webkit-keyframes slide-out {
    0% {
        -webkit-transform: translateX(0%);
    }

    100% {
        -webkit-transform: translateX(-100%);
    }
}