.menu {
    min-width: 100px;
    position: absolute;
    inset: 52px 0 auto auto;
    z-index: 3;

    background-color: #F0F0F0;
    border: 1px solid black;
    border-radius: var(--dcp-border-radius-sm);
}

.option {
    font-weight: bold;
    padding: 6px 10px;
}

.option:not(:first-child) {
    border-top: 1px solid gray;
}